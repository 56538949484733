import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { Converter } from "showdown";
import { graphql, Link } from "gatsby";
import Layout from "../components/Layout";
import { HTMLContent } from "../components/Content";
import Navbar from "../components/Navbar";
import PreviewCompatibleImage from "../components/PreviewCompatibleImage";

import { PersonIcon, TimeIcon } from "../components/Icons";

import "./styles/recipe-page.sass";

// eslint-disable-next-line
export const RecipePostTemplate = ({ recipe }) => {
  const mdConverter = new Converter();
  return (
    <section
      className={`v-recipe-page ${
        recipe.darkText ? "v-recipe-page--dark-text" : ""
      }`}
      style={{ backgroundColor: recipe.color || "#E8A402" }}
    >
      <div className="v-recipe-page__left">
        <div className="v-fullwidth-content">
          <div className="container">
            <div className="v-recipe-page__container">
              <div className="v-recipe-page__back-container">
                <Link className="v-recipe-page__back" to="/przepisy">
                  WRÓĆ DO PRZEPISÓW
                </Link>
              </div>
              <h2 className="v-recipe-page__title">{recipe.title}</h2>

              <div className="v-recipe-page__subheader">
                <div className="v-recipe-page__short-info-box">
                  <div className="v-recipe-page__short-info-box-value">
                    <TimeIcon /> {recipe.time}
                  </div>
                  czas przygotowania
                </div>
                <div className="v-recipe-page__short-info-box">
                  <div className="v-recipe-page__short-info-box-value">
                    <PersonIcon /> {recipe.portion}
                  </div>
                  na ile osób
                </div>
              </div>
              <div className="v-recipe-page__main">
                {recipe.ingredients && (
                  <div className="v-recipe-page__ingredients">
                    <div className="v-recipe-page__main-title">Składniki:</div>
                    <div className="v-recipe-page__main-content">
                      <HTMLContent
                        content={mdConverter.makeHtml(recipe.ingredients)}
                      />
                    </div>
                  </div>
                )}
                <div className="v-recipe-page__description">
                  <div className="v-recipe-page__main-title">
                    Przygotowanie:
                  </div>
                  <div className="v-recipe-page__main-content">
                    <HTMLContent
                      content={mdConverter.makeHtml(recipe.description)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {recipe.image && (
        <div className="v-recipe-page__right">
          <PreviewCompatibleImage
            imageInfo={recipe.image}
            style={{
              height: "100%",
              width: "100%",
            }}
          />
        </div>
      )}
    </section>
  );
};

RecipePostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  title: PropTypes.string,
  helmet: PropTypes.object,
};

const RecipePost = ({ data }) => {
  const { markdownRemark: recipe } = data;

  return (
    <Layout>
      <Helmet titleTemplate="%s | Przepis">
        <title>{`${recipe.frontmatter.title}`}</title>
        <meta
          name="description"
          content={`Przepis - Veganiola - ${recipe.frontmatter.title}`}
        />
      </Helmet>
      <Navbar externalPage dark={recipe.frontmatter.darkText} />
      {/*<section*/}
      {/*  id="123"*/}
      {/*  className="v-section v-section--blue v-section--full-screen v-recipes-page"*/}
      {/*>*/}
      {/*  <div className="v-fullwidth-content">*/}
      {/*    <div className="container">*/}
      {/*      <div className="v-recipes-page__header">*/}
      {/*        <div className="v-recipes-page__header-content-title">*/}
      {/*          Już wkrótce*/}
      {/*        </div>*/}
      {/*        <div className="v-recipes-page__header-content-desc">*/}
      {/*          {recipe.frontmatter.title}*/}
      {/*        </div>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</section>*/}
      <RecipePostTemplate recipe={recipe.frontmatter} />
    </Layout>
  );
};

RecipePost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
};

export default RecipePost;

export const pageQuery = graphql`
  query RecipePostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      frontmatter {
        title
        description
        ingredients
        time
        portion
        image {
          childImageSharp {
            gatsbyImageData(quality: 90, layout: CONSTRAINED)
          }
        }
        color
        darkText
        category
      }
    }
  }
`;
